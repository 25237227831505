<script setup lang="ts">
import AppLink, {
    LinkColor,
    LinkSize,
    LinkVariant,
} from "@/Components/Shared/link/AppLink.vue";
import { computed } from "vue";

type Props = {
    variant?: LinkVariant;
    color?: LinkColor;
    size?: LinkSize;
    label?: string;
    labelClass?: string;
    subject?: object;
};

const {
    variant = "buttonPrimary",
    color = undefined,
    size = "small",
    label = "Deck",
    labelClass = undefined,
    subject = undefined,
} = defineProps<Props>();

const deckLink = computed(() => {
    let link = `/decks/create`;

    if (subject && subject.type === "game") {
        link += `/?game=${subject.id}`;
    }

    return link;
});
</script>

<template>
    <AppLink
        class="h-full"
        :variant="variant"
        :size="size"
        :color="color"
        icon="plus"
        icon-size="xsmall"
        :href="deckLink"
    >
        <span :class="labelClass">
            {{ label }}
        </span>
    </AppLink>
</template>
